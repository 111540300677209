/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import React from 'react'
// import { PageProvider } from './src/context/PageContext'

// export const wrapRootElement = ({ element }) => (
//   <PageProvider>{element}</PageProvider>
// )
// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

//prismjs themes
//https://github.com/PrismJS/prism/tree/1d5047df37aacc900f8270b1c6215028f6988eb1/themes
require('prismjs/themes/prism-tomorrow.css')
require('prismjs/plugins/command-line/prism-command-line.css')
// require('prismjs/plugins/line-numbers/prism-line-numbers.css')
